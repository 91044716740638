import { message } from "antd";
import { useCallback, useEffect } from "react";

import {
  IEventDetail,
  dispatchRouteChangeEvent,
  registerRouteChangeListener,
  removeRouteChangeListener,
} from "eventListeners/routeEvents";
import { useKContext } from "@context";
import { ECardType } from "@types";

const WARNING_TEXT =
  "You have unsaved changes, are you sure you want to leave?";

export const useUnsavedChangesWarning = (isDirty: boolean) => {
  const { setCardTypeSelected } = useKContext();

  const handlePopState = useCallback((e: PopStateEvent) => {
    setCardTypeSelected('' as ECardType);
    if (!isDirty) return;
    message.warning("Your entered data was not saved!");
    window.onpopstate = null;
  }, [isDirty]);

  useEffect(() => {
    const handleReloadWindow = async (event: BeforeUnloadEvent) => {
      if (await window.location.href.startsWith("mailto:")) return;
      if (!isDirty) return;
      event.preventDefault();
      event.returnValue = WARNING_TEXT;
      return "";
    };

    const handleChangeRoute = (event: CustomEvent<IEventDetail>) => {
      if (!isDirty || window.confirm(WARNING_TEXT)) return;
      event.preventDefault();
      dispatchRouteChangeEvent("completed");
    };

    window.addEventListener("beforeunload", handleReloadWindow);
    registerRouteChangeListener("start", handleChangeRoute);
    window.onpopstate = handlePopState;

    return () => {
      window.removeEventListener("beforeunload", handleReloadWindow);
      removeRouteChangeListener("start", handleChangeRoute);
    };
  }, [isDirty, handlePopState]);
};
