import {
  ECardType,
  ErrorResultType,
  IAttachment,
  ICombinedCard,
  IParams,
  IPrincipalInfo
} from "@types";
import { createContext, useCallback, useContext, useReducer, useState } from "react";

import { breakpointLarge } from "@constants/size";
import { useCustomNavigate, useLoggingService } from "@hooks";
import { useMediaQuery } from "@react-hook/media-query";
import {
  getCardDetailsService,
  getTopicsUserService,
  updateCardService
} from "@services";
import { useDetailCard, useUser } from "@store";
import { getInfoCard } from "@utils/index";
import { message } from "antd";
import { useLocation, useParams } from "react-router-dom";
import {
  IGlobalContext,
  ISummaryCard,
  ITopic,
  KContextProps
} from "types";

export const KContext = createContext<IGlobalContext>({} as IGlobalContext);

export const KContextProvider = ({ children }: KContextProps) => {
  const { navigate } = useCustomNavigate();
  const { profileId: paramProfile } = useParams<IParams>();
  const [title, setTitle] = useState<string>("");
  const [expiredTime, setExpiredTime] = useState<string>("");
  const [selectedProfileIndex, setSelectedProfileIndex] = useState<number>(0);
  const [amountOfCards, setAmountOfCards] = useState<ISummaryCard[]>([]);
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [temporaryShareId, setTemporaryShareId] = useState("");
  const [profileId, setProfileId] = useState("");
  const [showProfileBasic, setShowProfileBasic] = useState(false);
  const [isViewingProfile, setIsViewingProfile] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [cardTypeSelected, setCardTypeSelected] = useState<ECardType>();
  const [isValidToken, setIsValidToken] = useState(false);
  const [showErrorResult, setShowErrorResult] = useState<ErrorResultType>(0);
  const [isSwitchToProfile, setIsSwitchToProfile] = useState(false);
  const [infoPrincipal, setInfoPrincipal] = useState<IPrincipalInfo>();
  const [newCard, setNewCard] = useState<string>("");
  const [isLoadingFullScreen, setIsLoadingFullScreen] = useState(false);
  const { userInfo } = useUser();
  const isMobile = useMediaQuery(`(max-width: ${breakpointLarge}px)`);
  const location = useLocation();
  const arrayPath = location.pathname.split('/');
  const { editableDetail } = useDetailCard();
  const { pushInfoTypeLog } = useLoggingService();

  const [forceUpdateListIndex, forceUpdateList] = useReducer(x => x + 1, 0);

  const fetchTopics = useCallback(async () => {
    if (!paramProfile) return;
    try {
      const topics = await getTopicsUserService(paramProfile);
      setTopics(topics);
    } catch (error) { }
  }, [paramProfile]);

  const onUpdateDetailCard = useCallback(async (data: any, reset?: (value: ICombinedCard) => void) => {
    const { path, title } = getInfoCard(data?.cardType);
    if (!profileId || !path || !editableDetail) return;
    const originalAttachments = data.attachments;
    const attachments = originalAttachments.map((item: IAttachment) => item._id);
    const payload = { ...data, attachments }
    try {
      const res = await updateCardService<any>(profileId, path, payload._id, payload);
      if (reset) {
        const resUpdated: any = await getCardDetailsService(profileId, path, payload._id);
        reset(resUpdated.data);
      }
      pushInfoTypeLog("MODIFIED", res);
      message.success(`Update ${title} successfully!`);
      forceUpdateList();
    } catch (err: any) {
      message.error(err?.response?.data?.message);
    }
  }, [profileId, editableDetail, pushInfoTypeLog]);

  const navigateDetailCard = useCallback((cardType?: ECardType, _id?: string, profile?: string) => {
    const pathCard = cardType ? `/${cardType}` : '';
    const pathId = _id ? `/${_id}` : '';
    if (arrayPath?.[1] === 'user' || userInfo) {
      return navigate(
        `/user/${profile || profileId}${pathCard}${pathId}`,
        undefined,
        () => { setCardTypeSelected(cardType); }
      );
    };
    navigate(
      `/${temporaryShareId}${pathCard}${pathId}`,
      undefined,
      () => { setCardTypeSelected(cardType); }
    );
  }, [arrayPath, paramProfile, temporaryShareId, userInfo, profileId]);

  return (
    <KContext.Provider
      value={{
        isMobile,
        title,
        setTitle,
        expiredTime,
        setExpiredTime,
        setAmountOfCards,
        amountOfCards,
        topics,
        setTopics,
        setTemporaryShareId,
        showProfileBasic,
        setShowProfileBasic,
        selectedProfileIndex,
        setSelectedProfileIndex,
        temporaryShareId,
        isViewingProfile,
        setIsViewingProfile,
        cardTypeSelected,
        setCardTypeSelected,
        isValidToken,
        setIsValidToken,
        profileId,
        setProfileId,
        isSwitchToProfile,
        setIsSwitchToProfile,
        infoPrincipal,
        setInfoPrincipal,
        setShowErrorResult,
        showErrorResult,
        newCard,
        setNewCard,
        onUpdateDetailCard,
        loadingSummary,
        setLoadingSummary,
        arrayPath,
        navigateDetailCard,
        fetchTopics,
        forceUpdateListIndex,
        forceUpdateList,
        isLoadingFullScreen,
        setIsLoadingFullScreen,
      }}
    >
      {children}
    </KContext.Provider>
  );
};

export const useKContext = () => {
  const context = useContext(KContext);

  if (!context) {
    throw new Error('useKContext only used within KContextProvider');
  }

  return context;
};
