import { KFormUser } from "@components";
import { MedicationMeasureOptions, MedicationStatusOptions, MedicationTypeOptions } from "@constants/options";
import { EMedicationFrequency } from "@types";
import { Flex } from "antd";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

const medicationFrequency = Object.values(EMedicationFrequency).map((i) => ({
  value: i,
  label: i,
}));

export const MedicationDetail = () => {
  return (
    <>
      <KFormUser.Input name="reason" label="Reason" placeholder="Reason" />
      <Flex justify="space-between" gap={16}>
        <KFormUser.InputUnit
          name="dosage"
          label="Dosage"
          options={MedicationMeasureOptions}
          selectName="measurement"
        />
        <KFormUser.Select
          name="type"
          label="Type"
          options={MedicationTypeOptions}
        />
      </Flex>
      <KFormUser.Switch name="supplement" label="Supplement?" />
      <KFormUser.Select
        label="Frequency"
        name="frequency"
        options={medicationFrequency}
      />
      <Flex vertical gap={8}>
        <KFormUser.TextArea
          name="instructions"
          label="Special Instructions"
          placeholder="Enter any guidance or important details"
        />
      </Flex>
      <Flex justify="space-between" gap={16}>
        <KFormUser.DatePicker label="Start Date" name="startTime" />
        <KFormUser.EndDatePicker label="End Date" name="endTime" />
      </Flex>
      <KFormUser.Select
        name="status"
        label="Status"
        options={MedicationStatusOptions}
      />
      <KFormUser.TextArea name="description" label="Additional Information" />
      <SetReminderSection />
      <AttachmentsDetail />
      <SetReminderSection />
    </>
  );
};
