import { KFormUser } from "@components";
import { ProcedureTypeOptions } from "@constants/options";
import { ECardType } from "@types";
import { AttachmentsDetail } from "./Attachments";
import { SetReminderSection } from "./SetReminderSection";

export const ProcedureDetail = () => {
  return (
    <>
      <KFormUser.DatePicker label="Procedure Date" name="startTime" />
      <KFormUser.Select
        label="Type"
        name="type"
        options={ProcedureTypeOptions}
      />
      <KFormUser.OptionsSelector
        label="Primary Practitioner"
        cardType={ECardType.Practitioners}
      />
      <KFormUser.TextArea
        label="Nurse(s)"
        name="nurse"
        placeholder="Enter nurse info"
      />
      <KFormUser.TextArea
        label="Location"
        name="location"
        placeholder="Enter location info"
      />
      <KFormUser.OptionsSelector
        label="Primary Related Condition"
        cardType={ECardType.Conditions}
      />
      <KFormUser.TextArea label="Additional Information" name="description" />
      <AttachmentsDetail />
      <SetReminderSection />
    </>
  );
};
