import { Flex } from 'antd';

import { KFormUser } from '@components';
import { QuestionStatusOptions } from '@constants/options';
import { ECardType } from '@types';
import { AttachmentsDetail } from './Attachments';
import { SetReminderSection } from './SetReminderSection';

export const QuestionDetails = () => {
  return (
    <>
      <Flex gap={16} vertical flex={1}>
        <KFormUser.OptionsSelector
          label="Practitioner"
          cardType={ECardType.Practitioners}
        />
        <KFormUser.OptionsSelector
          label="Appointment"
          cardType={ECardType.Appointments}
        />
        <KFormUser.TextArea label="Background Info" name="backgroundInfo" />
        <KFormUser.TextArea
          label="Answer"
          name="answer"
          placeholder="Enter any answers here"
        />
        <KFormUser.Select
          label="Status"
          name="status"
          options={QuestionStatusOptions}
        />
        <Flex gap={16} justify="space-between">
          <KFormUser.DatePicker label="Date Asked" name="startTime" />
          <KFormUser.EndDatePicker label="Date Answered" name="endTime" />
        </Flex>
        <SetReminderSection />
        <AttachmentsDetail />
        <SetReminderSection />
      </Flex>
    </>
  );
};
