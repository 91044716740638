import { ArrowLeftOutlined, HistoryOutlined } from "@ant-design/icons";
import { Flex, Result } from "antd";
import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { KButton } from "@components";
import { KContext } from "@context";
import { useCustomNavigate } from "@hooks";
import { Color, EUrlPath, IParams } from "@types";
import { removeToken } from "@utils/storage";
import { getResultMessage } from "types/enums/status-result";
import { heightHeader } from "@constants/size";
import styled from "styled-components";

const StyledRefreshPage = styled(Result)({
  height: `calc(100vh - ${heightHeader}px)`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const ErrorResult = () => {
  const { tempShareId, exportId, profileId } = useParams<IParams>();
  const { showErrorResult, setShowErrorResult } = useContext(KContext);
  const { navigate, goBack } = useCustomNavigate();

  const onClickNavigate = () => {
    setShowErrorResult(0);
    switch (true) {
      case Boolean(tempShareId):
        removeToken("TempShare");
        navigate(`/${tempShareId}/welcome`);
        break;
      case Boolean(profileId):
        removeToken("WebApp");
        navigate(`/auth/sign-in`);
        break;
      case Boolean(exportId):
        removeToken("Export");
        navigate(`/${EUrlPath.EXPORT}/${exportId}/welcome`);
        break;
      default:
        navigate(`/${tempShareId}/${EUrlPath.WELCOME}`);
        break;
    }
  };

  const title = useMemo(() => {
    switch (showErrorResult) {
      case "info":
        return "Your custom share link has expired";
      default:
        return showErrorResult;
    }
  }, [showErrorResult]);

  const onClickGoBack = () => {
    setShowErrorResult(0);
    goBack();
  };

  if (showErrorResult === "refresh") {
    return (
      <StyledRefreshPage
        icon={<HistoryOutlined style={{ color: Color.PrimaryBase }} />}
        title="Sorry, your session has expired."
        subTitle="Please click the button below to reload the page or reset the page."
        extra={
          <Flex justify="center" flex={1}>
            <KButton
              size="large"
              title="Reload"
              onClick={() => window.location.reload()}
            />
          </Flex>
        }
      />
    );
  }

  return (
    <Result
      status={showErrorResult || 404}
      title={title}
      subTitle={getResultMessage(showErrorResult)}
      extra={
        showErrorResult === 403 && (
          <Flex justify="center" gap={16}>
            <KButton
              ghost
              type="primary"
              title="Go Back"
              icon={<ArrowLeftOutlined />}
              onClick={onClickGoBack}
            />
            <KButton
              type="primary"
              title="Request OTP"
              onClick={onClickNavigate}
            />
          </Flex>
        )
      }
      style={{ height: "100%", backgroundColor: Color.White }}
    />
  );
};

export default ErrorResult;
