import { IInfoPrincipal } from "@types";

export enum AUTH_TYPE {
  EMAIL = 'email',
  SMS = 'sms',
}

export type submitProps = { email: string; type: AUTH_TYPE };

export enum EAuthPage {
  AUTH_SCREEN = "AUTH_SCREEN",
  WELCOME_USER = "WELCOME_USER",
  WELCOME_GUEST = "WELCOME_GUEST",
  VERIFY_OTP_LOGIN = "VERIFY_OTP_LOGIN",
  CSL_WELCOME = 'CSL_WELCOME',
  REQUEST_OTP = 'REQUEST_OTP',
  VERIFY_OTP = 'VERIFY_OTP',
  CONFIRM_LOGOUT = 'CONFIRM_LOGOUT',
}

export type AuthScreenProps = { page: EAuthPage; component: JSX.Element }[];

export interface IPrincipalInfo {
  accessToken?: string | null;
  existUser?: boolean;
  isSamePrincipal?: boolean;
  canAccess?: boolean;
  principal?: IInfoPrincipal;
}

export interface IPrincipalEmergencyAccessRes {
  firstName: string;
  lastName: string;
}

export interface IInfoVerifyEmergencyAccess {
  name: string,
  affiliation: string,
  timestamp?: Date | string,
  birthDate: Date | string;
}

export interface IEmergencyAccessHistory extends IInfoVerifyEmergencyAccess {}

export interface IAccessTokenResponse {
  accessToken: string;
  refreshToken: string;
}