import {
  CardItemStyled,
  KCheckBox,
  KLgText700,
  KMdText,
  KMdTextSecondary65,
  KSmTextSecondary65,
} from "@components";
import { ICardImport } from "@services";
import { formatDate } from "@utils/date";
import { Flex } from "antd";

interface IEMRCardImport {
  showCheckBox: boolean;
  card?: ICardImport;
  checked?: boolean;
  onChangeCheckbox?: () => void;
}

export const EMRCardImport = ({
  showCheckBox,
  card,
  checked,
  onChangeCheckbox,
}: IEMRCardImport) => {
  const { isImported, startTime, referenceName, source, title } = card || {};
  const onClickCheckBox = (e: any) => {
    if (isImported) return;
    e.stopPropagation();
    onChangeCheckbox?.();
  };

  return (
    <CardItemStyled gap={4} onClick={onClickCheckBox}>
      <Flex justify="space-between">
        <KLgText700
          $ellipsis
          style={{ flex: 1 }}
          color={isImported ? "Secondary65" : "SecondaryBase"}
        >
          {title}
        </KLgText700>
        {showCheckBox &&
          (isImported ? (
            <KMdTextSecondary65 children="Already Added" />
          ) : (
            <Flex gap={8} align="center">
              <KCheckBox checked={checked} onClick={onClickCheckBox}/>
              <KMdText>Add</KMdText>
            </Flex>
          ))}
      </Flex>
      <KSmTextSecondary65 $ellipsis>
        {`${
          startTime
            ? `${formatDate(startTime, "DAY_MONTH_YEAR_WITH_SPLASH")} • `
            : ""
        }${source} • ${referenceName}`}
      </KSmTextSecondary65>
    </CardItemStyled>
  );
};