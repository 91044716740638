export enum EChangeType {
  CREATED = 'Created',
  MODIFIED = 'Modified',
  DELETED = 'Deleted',
}

export enum EPermissionTypeShared {
  EDITOR = 'editor',
  CONTRIBUTOR = 'contributor',
  VIEWER = 'viewer',
  SHARE_LINK = 'share link',
}

export enum ELogType {
  PIN_ENTRY = 'pin-entry',
  INFO_CHANGE = 'info-change',
  SHARING = 'sharing',
}

export enum EInviteType {
  EMAIL = 'email',
  SMS = 'sms',
  EXIST_USER = 'exist_user',
}

export enum EInvitationStatus {
  COMPLETED = 'Completed',
  PENDING = 'Pending',
}

export enum EInfoType {
  INFO_CARD = "info type",
  TOPIC = "topic",
  PROFILE_BASICS = "profile basics",
  ALLERGIES = "allergy",
  APPOINTMENTS = "appointment",
  CONDITIONS = "condition",
  FILES = "file",
  HOSPITALIZATIONS = "hospitalization",
  ID_CARD = "id-card",
  MEDICATIONS = "medication",
  NOTES = "note",
  PRACTITIONERS = "practitioner",
  PROCEDURES = "procedure",
  QUESTIONS = "question",
  VACCINATIONS = "vaccination",
  FILE_GROUP = "file-group",
  TO_DO = "to-do",
  ACCOUNT_SETTINGS = "account-settings",
}
